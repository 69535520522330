<template>
  <div class="livePageWrap container">
    <header>
      <el-row class="hedaer-Bg" type="flex">
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class="logo-wrap">
              <img src="../../assets/image/homeImg/logo.png" />
            </div>
            <div class="app-title" @click="$router.push('/home')">
              河南省团干部网络教育平台
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')"
                    >账号安全</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="$router.push('/login')"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
    </header>
    <el-main class="main-content">
      <div>
        <el-row type="flex">
          <el-col class="leftContent">
            <div class="grid-content bg-purple-light grid-content-left">
              <div class="leftSide">
                <img src="../../assets/signSideNav.png" alt="" />
                <div>签到</div>
              </div>
              <div class="navAndSign">
                <div class="returnIcon" @click="$router.back(-1)">
                  < &nbsp;返回
                </div>
                <div class="title">签到</div>
                <div class="signWrap">
                  <div class="sign" @click="submitSignFun"   v-show="isSignin == 'N'">
                    <img
                      src="../../assets/sign.png"
                      alt=""
                    /><div class="nosignText">签到</div>
                  </div>

                  <div class="sign" v-show="isSignin == 'Y'">
                    <img
                      src="../../assets/nosign.png"
                      alt=""
                    /><div>已签到</div>
                  </div>
                   <!-- 签退，高亮 -->
                  <div class="signout" @click="submitSignOutFun" v-show="this.isSignout == 'N' && isSignin == 'Y'">
                    <img
                      v-show='isSignBtnShow==true'
                      src="../../assets/signout.png"
                      alt=""
                    />
                    <div v-show='isSignBtnShow==true' class="nosignoutText">签退</div>
                  </div>
                  <!-- 已签退 -->
                  <div class="signout" @click="submitSignOutFun" v-show="this.isSignout == 'Y' && isSignin == 'Y'">
                    <img
                      src="../../assets/nosignout.png"
                      alt=""
                    />
                    <div v-show='isSignBtnShow==false'>已签退</div>
                  </div>
                  <!-- 签退，灰色 -->
                  <div class="signout" v-show="this.isSignout == 'N' && isSignin == 'N'">
                    <img
                      src="../../assets/nosignout.png"
                      alt=""
                    />
                    <div class="nosignoutText_gray">签退</div>
                  </div>

                </div>
              </div>
            </div>
          </el-col>
          <el-col class="RightContent">
            <div class="grid-content bg-purple-light">
              <div class="chapterTitle">{{ chapterTitle }}</div>
              <div>
                <div class="topBg" v-if="visible"></div>
                <section v-if="visible" class="g-moblie-page-container">
                  <component
                    :is="componentTagName"
                    :channelInfo="channelInfo"
                    :chatInfo="chatInfo"
                    :apiToken="apiToken"
                    :productEnable="productEnable"
                    :donateConfig="donateConfig"
                    @change-switch="handleChangeSwitch"
                    @reload="reloadWatchPage"
                  />
                </section>
                <div class="bottomBg" v-if="visible"></div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div></div>
    </el-main>
    <footer></footer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import PolyvApi from '@/utils/api'
import * as PolyvUtil from '@/utils'
import {
  DonateMessageHub,
  DonateMessageHubEvents,
} from '@/components/Donate/DonateMixin'
const TIME_STAMP = +new Date()
export default {
  name: '',
  components: {
    PcWatch: () => import('@/components/Watch/PcWatch.vue'),
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      config: (state) => state.config,
      isPlvWebview: (state) => state.webview.isPlvWebview,
    }),
    componentTagName () {
      console.log(this.isMobile)
      return this.isMobile ? 'MobileWatch' : 'PcWatch'
    },
    // 控制签退是否显示与是否可点击
    showSignOutType () {
      let newDate = new Date()
      let endTime = new Date(this.endTime)
      // 时间已到，课程已经结束
      if (newDate > endTime) {
        return true
      } else {
        return false
      }
    },
    isSignBtnShow () {
      return this.isSignout == 'N' /// / 没有签退
    },
  },
  props: {},
  watch: {},
  data () {
    return {
      channelId: '',
      appId: '',
      visible: false,
      channelInfo: {},
      userName: '',
      chatInfo: {},
      apiToken: '',
      productEnable: false,
      timer: null,
      ChapterInfo: {},
      playData: {
        id: '',
        studentCourseId: '',
      },
      donateConfig: {},
      vodPlayerJs: 'https://player.polyv.net/livesdk/polyv-live.min.js',
      vid: '7c0bc55809facc6bd6b339c028b93a85_7',
      appSecret: '83426e92f4c24390a33e0733ab7de833',
      courseCover_url: '',
      isSignin: '',
      isSignout: '',
      chapterTitle: '',
      endTime: '',
      isShowSignout: false,
    }
  },
  methods: {
    ...mapMutations({
      updateNickname: 'config/updateNickname',
      updateUserId: 'config/updateUserId',
      setConfigBasicInfo: 'config/setBasicInfo',
      resetConfigChat: 'config/resetChat',
      updateWebviewPlayState: 'webview/updatePlayState',
    }),
    /** 重新渲染观看页 */
    async reloadWatchPage () {
      this.visible = false
      this.resetConfigChat()
      await this.init()
    },
    handleChangeSwitch (data) {
      Object.keys(data).forEach((key) => {
        this[key] = data[key]
      })
    },
    bindEventBus () {
      DonateMessageHub.on(
        DonateMessageHubEvents.SEND_REWARD_MSG,
        ({ data }) => {
          this.sendRewardMsg(data)
        }
      )
    },
    /** 初始化观看页需要的数据 */
    async init () {
      console.log('1111111111111111111111111111111')
      try {
        if (this.isPlvWebview) {
          // 将小窗里面用到的播放状态数据初始化
          this.updateWebviewPlayState(false)
        }
        // 获取聊天室信息
        this.chatInfo = await this.getChatInfo()
        console.log(
          this.chatInfo,
          'this.chatInfothis.chatInfothis.chatInfothis.chatInfothis.chatInfo'
        )
        // SDK设置接口token, 用于一些互动的功能接口的请求,如点赞
        this.apiToken = await this.getApiToken()
        // 获取频道信息
        this.channelInfo = await this.getChannelInfo()
        console.log(
          this.channelInfo,
          'channelInfochannelInfochannelInfochannelInfochannelInfochannelInfochannelInfo'
        )
        // 获取是否开启“商品库开关”
        this.productEnable = await this.getProductEnable()
        // 获取”打赏“配置
        this.donateConfig = await this.getDonateConfig()
        this.visible = true
      } catch (error) {
        console.error('接口请求失败！', error.message)
        this.$dialog.alert({
          message: '接口请求失败！' + error.message,
        })
      }
    },
    async getChannelInfo () {
      const channelInfoParams = {
        appId: this.config.appId,
        timestamp: TIME_STAMP,
        channelId: this.config.channelId,
      }

      // ！！！不要在前端生成sign，此处仅供参考
      channelInfoParams.sign = PolyvUtil.getSign(
        this.config.appSecret,
        channelInfoParams
      )

      return await PolyvApi.getChannelInfo(channelInfoParams)
    },
    async getChatInfo () {
      // 聊天室JS-SDK加载需要先请求校验码
      const chatApiParam = {
        appId: this.config.appId,
        timestamp: TIME_STAMP,
        channelId: this.config.channelId,
        userId: this.config.userId,
        role: this.config.role,
      }

      // ！！！不要在前端生成sign，此处仅供参考
      chatApiParam.sign = PolyvUtil.getSign(
        this.config.appSecret,
        chatApiParam
      )

      return await PolyvApi.getChatToken(chatApiParam)
    },
    async getApiToken () {
      const apiTokenParams = {
        appId: this.config.appId,
        timestamp: TIME_STAMP,
        channelId: this.config.channelId,
        viewerId: this.config.userId,
        nickName: this.config.nickname,
      }

      // ！！！不要在前端生成sign，此处仅供参考
      apiTokenParams.sign = PolyvUtil.getSign(
        this.config.appSecret,
        apiTokenParams
      )

      return await PolyvApi.getApiToken(apiTokenParams)
    },
    async getProductEnable () {
      const params = {
        appId: this.config.appId,
        timestamp: TIME_STAMP,
        channelId: this.config.channelId,
      }

      // ！！！不要在前端生成sign，此处仅供参考
      params.sign = PolyvUtil.getSign(this.config.appSecret, params)

      const enabled = await PolyvApi.getProductEnable(params)
      return PolyvUtil.ynToBool(enabled)
    },
    async getDonateConfig () {
      const params = {
        appId: this.config.appId,
        timestamp: TIME_STAMP,
        channelId: this.config.channelId,
      }

      // ！！！不要在前端生成sign，此处仅供参考
      params.sign = PolyvUtil.getSign(this.config.appSecret, params)

      return await PolyvApi.getDonateConfig(params)
    },
    async sendRewardMsg (donateData) {
      try {
        const params = {
          appId: this.config.appId,
          timestamp: TIME_STAMP,
          channelId: this.config.channelId,
          viewerId: this.config.userId,
          nickname: this.config.nickname,
          avatar: this.config.avatar,
          ...donateData,
        }

        // ！！！不要在前端生成sign，此处仅供参考
        params.sign = PolyvUtil.getSign(this.config.appSecret, params)

        await PolyvApi.sendRewardMsg(params)
      } catch (error) {
        console.error('接口请求失败！', error.message)
        this.$dialog.alert({
          message: '接口请求失败！' + error.message,
        })
      }
    },
    pageJumpLogin () {
      this.$router.push('/login')
    },
    // 获取local中用户信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
      this.updateNickname(this.userName)
      let userIdInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
      if (userIdInfo) {
        let {id} = userIdInfo
        this.updateUserId(id)
      }
    },
    // 上传进度
    uploadProgress () {
      let _this = this
      this.addStudyTrack()
      this.timer = setInterval(() => {
        _this.addStudyTrack()
      }, 30000)
    },
    addStudyTrack () {
      const params = {
        trackType: 'LIVE',
        studentChapterId: this.$route.query.id,
        studentCourseId: this.$route.query.studentCourseId,
      }
      console.log(params)
      this.$axios.post(this.$apiUrl.addStudyTrack, params).then((res) => {
        if (res && res.code === 200 && res.success) {
          console.log('res==', res)
          this.peopleNum = res.data
        }
      })
    },
    // 配置 config 内的 channelId, appId, appSecret
    // 设置直播的参数
    liveChannelInfo () {
      this.channelId = JSON.parse(localStorage.getItem('channelId'))
      this.appId = JSON.parse(localStorage.getItem('appId'))
      this.appSecret = JSON.parse(localStorage.getItem('appSecret'))
      this.setConfigBasicInfo({
        channelId: this.channelId,
        appId: this.appId,
        appSecret: this.appSecret,
      })
    },
    // 获取此章节的信息，包括签到，签退，直播id，背景图片
    getChapterInfo () {
      this.$axios
        .get(this.$apiUrl.loadStudentLiveChapter, {
          params: { id: this.$route.query.id },
        })
        .then((response) => {
          let res = response.data
          if (res && res.code === 200 && res.success) {
            this.isSignin = res.data.isSignin
            this.isSignout = res.data.isSignout
            this.chapterTitle = res.data.chapterTitle
            this.endTime = res.data.endTime
            let newDate = new Date()
            let endTime = new Date(this.endTime)
            this.isShowSignout = newDate > endTime
            console.log(this.isShowSignout)
            if (!this.isShowSignout) {
              this.watchTime()
            }
            this.ChapterInfo = res.data
            // 设置直播间id
            let channelId = this.ChapterInfo.liveId
            // 存储到本地
            localStorage.setItem('channelId', JSON.stringify(channelId))
            this.liveChannelInfo()
            this.init()
            this.bindEventBus()
            this.uploadProgress()
          }
        })
    },
    // 提交签到
    submitSignFun () {
      //   if (this.isSignin == 'Y') return
      this.$axios
        .post(this.$apiUrl.saveLiveChapterSignin, { id: this.$route.params.id })
        .then((response) => {
          let res = response.data
          if (res.success) {
            this.isSignin = 'Y'
            this.$message({
              type: 'success',
              message: '签到成功',
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
            })
          }
        })
    },
    // 提交签退
    submitSignOutFun () {
      this.$axios
        .post(this.$apiUrl.saveLiveChapterSignout, {
          id: this.$route.params.id,
        })
        .then((response) => {
          let res = response.data
          if (res.success) {
            this.isSignout = 'Y'
            this.$message({
              type: 'success',
              message: '签退成功',
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
            })
          }
        })
    },
    watchTime () {
      this.timer = setInterval(() => {
        let newDate = new Date()
        let endTime = new Date(this.endTime)
        this.isShowSignout = newDate > endTime
        console.log(this.isShowSignout)
        if (this.isShowSignout) {
          clearInterval(this.timer)
          this.timer = null
        }
      }, 5000)
    },
  },
  created () {
    this.getUserInfo()
  },
  mounted () {
    this.getChapterInfo()

    // this.liveChannelInfo()
    // this.init()
    // this.bindEventBus()
    // this.uploadProgress()
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  destroyed () {
    this.timer = null
    if (this.player) {
      this.player.destroy()
    }
  },
}
</script>

<style scoped lang="scss">
.livePageWrap {
  position: relative;
  min-height: 100vh;
  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 42px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1440px;
    margin: 0 auto;
    line-height: 42px;
    padding: 0 100px;

    .header-left-content {
      font-size: 20px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 33px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 30px;
        line-height: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      ::v-deep .el-dropdown {
        font-size: 14px;
        color: #4774df;
        background-color: #fff;
        height: 30px;
        line-height: 30px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }
  .main-content {
    position: relative;
    width: 1340px;
    margin: 0 auto;
    .grid-content-left {
      display: flex;
      .leftSide {
        width: 74px;
        height: 699px;
        background: #2f3a4a;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        color: #ffff;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        padding-top: 10px;
        img {
          width: 26px;
          height: 26px;
        }
      }
      .navAndSign {
        height: 699px;
        background: #ffffff;
        .returnIcon {
          padding: 24px 0px 30px 22px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          cursor: pointer;
        }
        .returnIcon:hover {
          color: #a4c2fa;
        }
        .title {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          border-bottom: 1px solid rgba(112, 112, 112, 0.2);
          padding: 9px 18px;
        }
      }
      .signWrap {
        width: 249px;
        height: 593px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div {
          padding: 30px 0px;
          color: "#000000";
          display: flex;
          flex-direction: column;
          font-size: 18px;
          text-align: center;
          cursor: pointer;
          > img {
            width: 50px;
            height: 50px;
          }
        }
        .nosignText {
          color: #1c4ecc;
        }
        .nosignoutText {
          color: #ff6f00;
        }
        .nosignoutText_gray{
          color:#a3a3a3
        }
      }
    }
    .topBg {
      height: 50px;
      background-color: #000;
    }
    .bottomBg{
      height: 100px;
      background-color: #000;
    }
    .RightContent{
      width: 1045px;
    }
  }
  footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 50px;
    background: url("../../assets/image/homeImg/headerBg.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
